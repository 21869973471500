<template>
  <div class="earlywarning-analysis">
    <div class="w-crumbs-href fl">
      <span>您现在所在的位置：</span>
      <router-link
        tag="span"
        class="link-item"
        :to="{ path: '/storeEarlywarning' }"
      >店铺预警分析</router-link>
      <i class="el-icon-arrow-right"></i>
      <span>{{storeData.name}}</span>
    </div>
    <div
      v-if="hasRightShow"
      class="filter-panel fr"
    >
      <div class="w-input-sty project-select">
        <el-select
          popper-class="w-block-select-down yellow-select-panel"
          class="select-extra"
          v-model="filtroProject"
          size="small"
          placeholder="请选择"
          filterable
          @change="getDataStore(1)"
        >
          <el-option
            v-for="item in projectOptions"
            :key="item.id"
            :label="item.projectName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          popper-class="w-block-select-down yellow-select-panel"
          class="select-extra"
          v-model="filtroStore"
          filterable
          size="small"
          placeholder="请选择"
          @change="changeStore"
        >
          <el-option
            v-for="item in storeOptions"
            :key="item.storeCode"
            :label="item.storeName"
            :value="item.storeCode"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="clearfix"></div>
    <div
      v-if="hasRightShow"
      class="sidebar"
      :class="{'fixed-left': isFixedSidebar}"
    >
      <div class="project-info">
        <div
          class="store-logo"
          :class="{'main-project': Number(storeData.mainStoreType) === 1}"
        >
          <img
            v-if="storeData.img"
            :src="storeData.img"
            width="96"
            height="80"
            alt=""
          >
          <img
            v-else-if="hasSetBrandId"
            class="mainProject"
            src="~@/assets/images/public/notPic.jpg"
            alt=""
            width="96"
            height="80"
          >
        </div>
        <p class="store-name ellipsis">{{storeData.name}}</p>
        <p
          :title="storeData.businessForm"
          class="business-form ellipsis"
        >{{storeData.businessForm}}</p>
        <span
          class="button-basic"
          v-if="storeData.attention === 0"
          @click="handleFollow"
          :class="disabled?'pointer-evnets-none':'pointer-evnets-auto'"
        >添加监控</span>
        <span
          class="button-basic button-active"
          :class="disabled?'pointer-evnets-none':'pointer-evnets-auto'"
          v-else
          @mouseover="buttonText='取消监控'"
          @mouseout="buttonText='已监控'"
          @click="handleFollow"
        >{{buttonText}}</span>
      </div>
      <ul>
        <router-link
          tag="li"
          v-for="item in sidebarList"
          :class="{'active-item': 'comparisonContent' === item.chil && key === 'comparisonContent' ||
            (item.value === 'projectComparison' && key === 'projectComparison') ||
            (item.value === 'rentAnalysis' && key === 'rentAnalysis') ||
            (item.value === 'salesAnalysis' && key === 'salesAnalysis') ||
            (item.value === 'evaluationAnalysis' && key === 'evaluationAnalysis')}"
          :key="item.value"
          :to="{
            name: item.value,
            query: {
              storeCode: filtroStore,
              projectId: filtroProject
            }
          }"
        >
          <i :class="'icon-'+camelToDash(item.value)"></i>
          {{item.label}}
        </router-link>
      </ul>
      <div class="button-check-ew">
        <span
          class="button-basic"
          @click="handleSettingPanel"
        >查看预警</span>
      </div>
    </div>
    <div
      class="mainpanel"
      ref="elementHeight"
      v-if="filtroProject && filtroStore && hasSetBrandId && hasRightShow"
    >
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <router-view
          :key="key"
          :brandId="brandId"
          :filtroProject="filtroProject"
          :filtroStore="filtroStore"
          :projectName="projectName"
          :storeOptions="storeOptions"
        ></router-view>
      </transition>
    </div>
    <div
      class="setting-panel"
      v-show="showSettingPanel"
    >
      <div class="content-box yellow-select-panel">
        <span
          class="el-icon-close pointer"
          @click="handleSettingPanel"
        >
        </span>
        <h3>
          查看预警配置
          <el-tooltip
            class="item"
            effect="dark"
            content="实时显示当日变更的预警规则，预警结果将在次日更新"
            placement="right"
          >
            <span>
              <i class="icon-help-tips"></i>
            </span>
          </el-tooltip>
        </h3>
        <table
          v-for="(item,index) in settingList"
          :key="index"
        >
          <tr>
            <td
              class="cls-common"
              colspan="3"
            >{{item.measureTypeName}}<span class="fr cs-w">预警规则：{{item.appliedRangeName || '-'}}</span></td>
          </tr>
          <tr
            v-for="(ele, i) in item.items"
            :key="i"
          >
            <td
              width="113"
              class="cls-common"
            >{{ele.measureName || '-'}}</td>
            <td
              width="153"
              class="cls-low"
            >{{ele.ruleConditionDesc || '-'}}</td>
            <td :class="{'cls-highlight': ele.warningFlag === 1}">{{ele.measureResultValue || '-'}}</td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="simpleInfoPanel"
      v-if="!hasRightShow"
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'earlywarningAnalysis',
  data () {
    return {
      showSettingPanel: false,
      settingList: [],
      brandId: 0,
      hasSetBrandId: false,
      filtroProject: 0,
      projectOptions: [],
      storeOptions: [],
      storeData: {
        name: '',
        img: '',
        businessForm: ''
      },
      sidebarList: [
        {
          label: '基础数据',
          value: 'basicData'
        },
        {
          label: '销售分析',
          value: 'salesAnalysis'
        },
        {
          label: '租金分析',
          value: 'rentAnalysis'
        },
        {
          label: '项目对比',
          value: 'projectComparison',
          chil: 'comparisonContent'
        },
        {
          label: '选址分析',
          value: 'sitingAnalysis'
        },
        {
          label: '评测分析',
          value: 'evaluationAnalysis'
        },
        {
          label: '招调推荐',
          value: 'merchantsRecommend'
        }
      ],
      buttonText: '已监控',
      disabled: false,
      isFixedSidebar: false,
      hasRightShow: false,
      filtroStore: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    key () {
      return this.$route.name
    },
    urlGetProjectId: { // query.projectId 存储用户选中的项目状态
      get: function () {
        return Number(this.$route.query.projectId) || ''
      }
    },
    urlGetStoreCode: { // query.storeCode 存储用户选中的店铺状态
      get: function () {
        return this.$route.query.storeCode || ''
      },
      set: function (newValue) {
        // console.log('newValue', newValue)
      }
    }
  },
  mounted () {
    const userAuthorities = this.user.confIds.find(item => {
      return item.id === 172
    })
    if (userAuthorities) {
      if (userAuthorities.func.indexOf('show') !== -1) {
        this.hasRightShow = true
      }
    } else {
      this.hasRightShow = false
    }
    this.getDataProjList()
    window.addEventListener('scroll', this.setFixedMenu)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.setFixedMenu)
  },
  watch: {
    '$route' (to, from) {
      this.$nextTick(() => {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
      })
      if (this.filtroStore !== to.query.storeCode && (from.name === 'salesAnalysis' || from.name === 'rentAnalysis')) {
        this.$router.go(0)
      }
    }
  },
  methods: {
    setFixedMenu (e) {
      // const scrollPosition = document.documentElement.clientHeight - window.pageYOffset
      if (window.pageYOffset > 175) {
        this.isFixedSidebar = true
      } else {
        this.isFixedSidebar = false
      }
    },
    getDataSetting () {
      const params = {
        storeCode: this.storeData.storeCode
      }
      this.axios.get(api.getStoreWarning, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.settingList = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleSettingPanel () {
      this.showSettingPanel = !this.showSettingPanel
      if (!this.showSettingPanel) { // 禁止滚动
        document.body.style.overflowY = 'auto'
      } else { // 允许滚动
        document.body.style.overflowY = 'hidden'
      }
    },
    handleFollow () {
      this.disabled = true
      const params = {
        storeCode: this.storeData.storeCode
      }
      this.axios.post(api.attentionStore, params).then(res => {
        if (res.data.code === 0) {
          this.getDataSidebarInfo()
          setTimeout(() => {
            this.disabled = false
          }, 500)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataSidebarInfo () {
      const params = {
        storeCode: this.filtroStore
      }
      this.axios.get(api.getStoreSideInfo, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.storeData = resData
          this.storeData.name = resData.storeName
          this.storeData.img = resData.brandLogo
          this.storeData.businessForm = resData.brandIndustry
          this.brandId = resData.brandId
          this.hasSetBrandId = true
          this.getDataSetting()
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    changeStore () {
      localStorage.setItem('isComparison', 'false')
      this.handleData()
    },
    handleData () {
      const isComparison = localStorage.getItem('isComparison')
      if (this.$route.name !== 'comparisonContent') {
        if (this.$route.name !== 'basicData') {
          this.$router.push({
            path: '/earlywarningAnalysis/basicData',
            query: Object.assign({ projectId: this.filtroProject, storeCode: this.filtroStore }, { _: +new Date() })
          })
        } else {
          this.$router.push({
            query: Object.assign({ projectId: this.filtroProject, storeCode: this.filtroStore }, { _: +new Date() })
          })
        }
      } else {
        if (isComparison === 'false') {
          this.$router.push({
            path: '/earlywarningAnalysis/basicData',
            query: Object.assign({ projectId: this.filtroProject, storeCode: this.filtroStore }, { _: +new Date() })
          })
        }
      }
      this.getDataSidebarInfo()
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.filtroProject
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getDataStore (key) { // 获取店铺列表，key为1时代表切换项目时，其他代表进入页面或者刷新页面
      // localStorage.setItem('isComparison', 'false')
      const projectObj = this.projectOptions.find((item) => {
        return item.id === this.filtroProject
      })
      this.projectName = projectObj.projectName
      this.axios.get(api.searchStore, {
        params: {
          projectId: this.filtroProject
        }
      }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.storeOptions = resData
            if (!this.urlGetStoreCode || key === 1) { // 如果url没有拼接storeCode或者切换项目
              this.filtroStore = resData[0].storeCode
              this.updateProjectIdFunc()
              localStorage.setItem('isComparison', 'false')
              // this.brandId = this.storeOptions[0].brandId
            } else { // 如果url拼接了storeCode
              this.filtroStore = this.urlGetStoreCode
              const res = resData.find((item) => {
                return item.storeCode === this.urlGetStoreCode
              })
              if (res === undefined) {
                this.filtroStore = resData[0].storeCode
              }
              // const urlGetStoreInfo = resData.filter(item => {
              //   return item.storeCode === this.filtroStore
              // })
              // if (urlGetStoreInfo && urlGetStoreInfo.length) {
              //   this.brandId = urlGetStoreInfo[0].brandId
              // }
            }
            this.handleData()
          } else {
            this.$message({
              type: 'warning',
              message: '该项目不存在在营店铺，无法选择'
            })
            this.filtroProject = this.urlGetProjectId
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProjList () { // select项目列表
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          if (this.projectOptions.length > 0) {
            if (this.urlGetProjectId !== '') { // 如果url中存在参数projectId
              const urlSetProject = this.projectOptions.find(item => { // 查看url中所指项目是否在项目列表中
                return item.id === this.urlGetProjectId
              })
              if (urlSetProject === undefined) {
                this.filtroProject = this.projectOptions[0].id // 默认选中第一个项目
                this.projectName = this.projectOptions[0].projectName // 默认选中第一个项目
                this.$router.push({
                  query: Object.assign({ projectId: this.filtroProject }, this.$route.query, { _: +new Date() })
                })
              } else {
                this.filtroProject = this.urlGetProjectId
                this.projectName = urlSetProject.projectName
              }
            } else {
              this.filtroProject = this.projectOptions[0].id // 默认选中第一个项目
              this.projectName = this.projectOptions[0].projectName // 默认选中第一个项目
              this.$router.push({
                query: Object.assign({ projectId: this.filtroProject }, this.$route.query, { _: +new Date() })
              })
            }
          }
          this.getDataStore()
        }
        this.loading = false
      }, rej => {
        console.log(rej)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    camelToDash (str) {
      return str.replace(/[A-Z]/g, function (item) {
        return '-' + item.toLowerCase()
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.earlywarning-analysis>>>
  .el-select__caret
    color #fff !important
.earlywarning-analysis
  color #fff
  .filter-panel
    margin 10px 23px 0 0
    .el-select:first-child
      margin-right 14px
  .fixed-left
    position fixed !important
    height 100% !important
    top 0
    left 0
  .sidebar
    position absolute
    width 180px
    // height 120vh
    height 89%
    background #272930
    z-index 111
    .project-info
      text-align center
      padding-bottom 24px
      .store-logo
        img
          margin 16px auto 14px auto
          border-radius 2px
          object-fit cover
          border 1px solid #424752
      .main-project
        &:after
          content '主'
          padding 0 2px
          display inline-block
          background #FF7134
          border-radius 0px 2px 0px 2px
          position absolute
          top 16px
          right 37px
      .store-name
        font-size 16px
        width 142px
        margin 0 auto 8px auto
      .business-form
        width 108px
        margin 0 auto 14px auto
        padding 0 11px
        font-size 12px
        line-height 22px
        color #B2B3BD
        background #3D3F45
        border-radius 11px
    .button-check-ew
      width 100%
      margin-top 35px
      text-align center
    ul
      border-top 1px solid #373A43
      padding-top 25px
      li
        margin 6px 0
        height 180px
        height 44px
        line-height 44px
        cursor pointer
        color #808191
        padding-left 37px
        i
          margin-right 5px
        &.router-link-exact-active, &:hover, &.router-link-active
          background #33343A
          border-left 3px solid #FFA134
          color #fff
          transition background, color 0.18s ease-in-out
          transition border 0.1s ease-in-out
          i:before
            color #fff !important
        &.active-item
          background #33343A
          border-left 3px solid #FFA134
          color #fff
          transition background, color 0.18s ease-in-out
          transition border 0.1s ease-in-out
          i:before
            color #fff !important
  .mainpanel
    width calc(100% - 200px)
    margin-left 200px
  .setting-panel
    position fixed
    z-index 999
    top 0
    left 0
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.4)
    .content-box
      width 615px
      overflow-y auto
      background #272930
      box-shadow 0px 4px 50px rgba(0, 0, 0, 0.1)
      border-radius 2px
      position fixed
      left calc(50% - 307px)
      top 200px
      padding-bottom 7px
      .el-icon-close
        position absolute
        top 19px
        right 19px
        width 22px
        height 22px
        border-radius 22px
        text-align center
        line-height 22px
        &:hover
          background #2c2e38
          box-shadow 0px 3px 6px rgba(0, 0, 0, 0.3)
      h3
        font-size 18px
        font-weight bold
        margin 18px 0 16px 19px
      table
        margin-bottom 17px
        font-size 14px
        border-top 1px solid #373a43
        border-left 1px solid #373a43
        width 96%
        margin 0 auto 17px auto
        color #b2b3bd
        td
          padding 12px 22px
          border-bottom 1px solid #373A43
          border-right 1px solid #373A43
.w-crumbs-href
  margin 11px 0 9px 33px
  span
    color #808191
    cursor default
    &.link-item
      cursor pointer
      &:hover
        text-decoration underline
.pointer-evnets-none
  background #32333C !important
  color #51555F !important
.select-extra>>>
  background #373A43
  .el-input__inner
    box-sizing border-box
    width 200px
    height 36px
    border 1px solid #373a43
    color #808191
    font-size 14px
</style>
